<template>
    <div id="previewWrapper">
        <top-search></top-search>
        <div class="container" v-show="soi_sent">
            <div class="row">
                <div class="col-md-12 text-center" style="padding-top: 25px;margin-bottom: 55px" >
                    <h3>Vielen Dank</h3>
                    <p>Wir haben eine bestätigungmail an {{doiMail}} gesendet. <br>Bitte bestätige kurz deine E-Mail adresse um die Arbeit: "{{item.item_title}}" runterzuladen. <br>Solltest du keine Mail erhalten haben wirf doch mal einen Blick in deinen Spam Ordner</p>
                </div>
            </div>
        </div>
        <div class="container"  v-show="!soi_sent">
            <div class="row">
                <div class="col-md-12">
                    <div class="breadCrump">
                        <span>
                            <router-link to="/">
                                Startseite
                            </router-link>
                        </span>
                        |
                        <span>
                            <router-link :to="{ name: 'cluster', params: {cluster: $route.params.cluster } }">
                                {{$route.params.cluster}}
                            </router-link>
                        </span>
                        |
                        <span>
                            <router-link :to="{ name: 'class', params: {cluster: $route.params.cluster,class: $route.params.class } }">
                                {{$route.params.class}}
                            </router-link>
                        </span>
                        |
                        <span>
                            <router-link :to="{ name: 'letter', params: {cluster: $route.params.cluster,class: $route.params.class,letter: $route.params.letter } }">
                                {{$route.params.letter}}
                            </router-link>
                        </span>
                        |
                        <span>
                            {{item.item_title}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="infoBox">
                                <h3>{{item.item_title}}</h3>
                                <p>{{item.description}}</p>
                                <table class="table detailInfos">
                                    <tr>
                                        <td>Von:</td>
                                        <td>{{item.author}}</td>
                                    </tr>
                                    <tr>
                                        <td>Fach:</td>
                                        <td>
                                            {{item.class_1}}
                                            <span v-if="item.class_2"> / {{item.class_2}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Kategorie:</td>
                                        <td>{{item.cluster}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="soiBox" id="soiBox">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3>Komplette Arbeit Lesen</h3>
                                        <p>
                                            Du möchtest die vollständige Arbeit lesen?<br>
                                            Gib einfach deine E-Mail-Adresse ein damit wir dir die <b>ganze Arbeit kostenlos als PDF</b> zur Verfügung stellen können.
                                        </p>
                                        <div class="form-group">
                                            <input class="form-control" type="email"  v-model="doiMail" placeholder="* Deine E-Mail-Adresse">
                                        </div>

                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="doiNL" id="newsletterCheck">
                                            <label class="form-check-label" for="newsletterCheck"  style="font-size: 14px">
                                                * Ich möchte den täglichen Newsletter der Digital Mail Solution GmbH und den <b data-toggle="modal" data-target="#sponsor">hier</b> aufgelisteten Sponsoren mit Informationen zu deren Angeboten aus den <b data-toggle="modal" data-target="#area">hier</b> aufgelisteten Bereichen per E-Mail erhalten. Meine Daten werden keinesfalls an Dritte weitergegeben. Meine Einwilligung kann ich jederzeit per E-Mail an abmeldung@pausenhof.de mit Wirkung für die Zukunft widerrufen. Zudem ist in jeder E-Mail ein Link zur Abbestellung weiterer Informationen enthalten.
                                            </label>
                                        </div>
                                        <br>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="doiSecure" id="dataProtection">
                                            <label class="form-check-label" for="dataProtection" style="font-size: 14px">
                                                * Die
                                                <router-link to="/datenschutz" class="mega-menu" target='_blank'>
                                                    Datenschutzerklärung
                                                </router-link> habe ich gelesen und akzeptiert.
                                            </label>
                                        </div>
                                        <br>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="doiAge" id="ageVerify">
                                            <label class="form-check-label" for="ageVerify"  style="font-size: 14px">
                                                * Ja! Ich bin mindestens 18 Jahre alt.
                                            </label>
                                        </div>
                                        <br>
                                        <span v-on:click="sendDoi()" class="btn btn-primary "> Arbeit als PDF runterladen</span>
                                        <br>
                                        <small>* Pflichtangaben</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="adsense">
                        <div class="row">
                            <div class="col-12">
                                <Adsense v-if="adsense"
                                         data-ad-client="ca-pub-2696232756207990"
                                         data-ad-slot="8851516560">
                                </Adsense>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <h4>Leseprobe</h4>
                    <div style="white-space: pre-line" >
                        {{item.plaintext | str_limit(2300)}}
                        <br><br>
                        <div class="text-center">
                            <a href="#soiBox">Um die ganze Arbeit runterzuladen klick einfach hier.</a>

                        </div>
                        <br><br>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Vielen Dank</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Der Download ist derzeit deaktiviert.<br> Probieren sie es später erneut.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">ok</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="sponsor" tabindex="-1" role="dialog" aria-labelledby="sponsorlabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Sponsoren</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <p><b>Rhein-Medial GmbH</b></p>
                                <p><a href="https://www.rhein-medial.de" target="_blank">https://www.rhein-medial.de</a></p>
                                <p>Maarstrasse 84</p>
                                <p>53227 - Bonn</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <p><b>Digital-Mail-Solution GmbH</b></p>
                                <p><a href="https://www.digital-mail-solution.com" target="_blank">https://www.digital-mail-solution.com</a></p>
                                <p>Gormannstrasse 14</p>
                                <p>10119 - Berlin</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <p><b>Travel Media Network GmbH</b></p>
                                <p><a href="https://www.travel-media-network.de" target="_blank">https://www.travel-media-network.de</a></p>
                                <p>Maarstrasse 84</p>
                                <p>53227 – Bonn</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Schließen</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="area" tabindex="-1" role="dialog" aria-labelledby="spaceslabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Mit Ihrem Werbeeinverständnis stimmen Sie einem täglichen Newsletter durch uns zu.</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <p>
                                    <b>Geschäftsbereich: </b>
                                    Wir versenden E-Mail Werbung sowohl im eigenen Namen als auch für Dritte, in denen Produkte oder Dienstleistungen aus den Bereichen Automotive, Mode, Elektronik, Finanzen, Lebensmittel, Haus & Garten, Reisen, Tourismus, Software, Telekommunikation, Versicherungen und Zeitschriften.
                                    Eine weitere Begrenzung des Gegenstands der Werbung ist nicht möglich, da wir von Kundenaufträgen leben und sich die Kunden und ihre Produkte ändern können. Wenn Ihnen dies nicht konkret genug ist, dann melden Sie sich bitte nicht an.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Schließen</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="failModal" tabindex="-1" role="dialog" aria-labelledby="failModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Fehler</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Bitte mache alle Pflichtangaben.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import TopSearch from "../components/TopSearch";
    import $ from 'jquery';
    import Vue from "vue";
    import Ads from 'vue-google-adsense'
    Vue.use(require('vue-script2'))
    Vue.use(Ads.Adsense)
    Vue.use(Ads.InArticleAdsense)
    Vue.use(Ads.InFeedAdsense)

    export default {
        name: 'Letter',
        components: {TopSearch},
        data: function () {
            return {
                item:{},
                doiMail:"",
                doiNL:false,
                doiSecure:false,
                doiAge:false,
                doiError:false,
                soi_sent:false,
                adsense : false
            }
        },
        methods:{
            getItems: function () {
                axios.get('https://api.pausenhof.de/item/details/'+this.$route.params.item,{params:{'limit': 1},headers:{'authorization': '3241451345'}}).then(response => {
                    this.item = response.data.content;
                });
            },
            sendDoi: function (){
                this.doiError = false;
                if(this.doiAge == false)
                    this.doiError = true;
                if(this.doiSecure == false)
                    this.doiError = true;
                if(this.doiNL == false)
                    this.doiError = true;
                if(this.doiMail.length == 0)
                    this.doiError = true;

                if(this.doiError == false){
                    axios({
                        method: 'post',
                        url: 'https://api.pausenhof.de/item/soi',
                        headers: {'authorization': '3241451345'},
                        data: {
                            item: this.$route.params.item,
                            email: this.doiMail
                        }
                    }).then(response => {
                        this.soi_sent = true;
                        console.log(response.data);
                    });
                }else{

                    $('#failModal').modal('show');
                }
            }
        },
        created() {
            this.getItems();
            let cookieConsent=  Vue.$cookies.get("rephCookieCons");
            if(cookieConsent == '111') {
                this.adsense = true;
            } else if(cookieConsent == '101') {
                this.adsense = true;
            } else if(cookieConsent == '110') {
                this.adsense = false;
            }
        }
    }
</script>

<style scoped>
    #sponsor p{
        margin-bottom: 0px;

    }
    #previewWrapper .btn-primary{
        background:#f76d6d;
        border-color:#f76d6d;
        color:#fff !important;
    }
    .soiBox{
        padding:15px;
        background:#87cbf0;
        margin:25px 0px;
    }
    .infoBox{
        padding:15px;
        background:#f1f1f1;
    }
    .breadCrump{margin-bottom: 25px;}
    .detailInfos td{
         padding: .35rem 0;
    }

</style>

